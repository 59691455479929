import React from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import api from "state/api";

import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class Dashboard extends React.Component {
  state = {
    productsMissingWeights: []
  };
  componentDidMount() {
    this.loadProductsMissingWeights();
  }

  loadProductsMissingWeights(date) {
    fetch(`/api/products/missingWeights`)
      .then(response => api.authCheck(response))
      .then(data => {
        if (data['data']) {
          this.setState({ productsMissingWeights:  data['data'] });
        }
       });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
      {this.state.productsMissingWeights.map(product => {
        return [
          <GridItem md={12} className={classes.notification}>
            <h4 className={classes.description}>
              <b>{ product["title"] }</b> does not have weight specified for all its variants.
            </h4>
          </GridItem>
        ];
      })}
      </GridContainer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
