import {
  blackColor,
  grayColor,
  hexToRgb,
  lightBoxShadow,
  roseColor,
  successColor,
  tooltip,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const reportStyle = theme => ({
  actionButton: {
    backgroundColor: "#00acc1",
    margin: "3px",
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.12)",
    "&:hover": {
      backgroundColor: "#26c6da",
    },
    "&:focus": {
      backgroundColor: "#26c6da",
    }
  },
  agendaCount: {
    float: "right",
    fontWeight: "600",
    marginLeft: "10px"
  },
  agendaEmbed: {
    position: "relative"
  },
  alertButton: {
    backgroundColor: "#f44336",
    margin: "3px",
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.12)",
    "&:hover": {
      backgroundColor: "#ff5e4c",
    },
    "&:focus": {
      backgroundColor: "#ff5e4c",
    }
  },
  alertHeader: {
    color: "#f44336",
    fontWeight: "600",
    textAlign: "center",
  },
  alertText: {
    fontSize: "1.4em",
    lineHeight: "1.8em",
    marginTop: "16px"
  },
  artNumber: {
    color: "#444",
    display: "block"
  },
  autoMargin: {
    margin: "0 auto"
  },
  bottomButton: {
    borderTop: "1px solid #ffffff55",
    color: "#fff",
    display: "block",
    fontSize: "1.1em",
    fontWeight: "600",
    marginTop: "8px",
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "8px",
    right: "0",
    textAlign: "center",
    width: "100%",
    "&:hover": {
      color: "#fff",
    },
    "&:focus": {
      color: "#fff",
    }
  },
  basicCard: {
    marginBottom: "50px",
    "& h3": {
      marginLeft: "24px"
    },
  },
  basicCardText: {
    color: "#fff",
    maxWidth: "250px",
    marginLeft: "8px",
    marginTop: "20px",
    paddingBottom: "8px",
    paddingTop: "8px",
  },
  button: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: roseColor[0],
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.12)",
    border: "none",
    borderRadius: "3px",
    position: "relative",
    padding: "6px 16px",
    margin: ".3125rem 8px",
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "uppercase",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: roseColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.2)"
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "18px",
      height: "18px",
      marginRight: "4px",
      verticalAlign: "middle"
    }
  },
  calendarEmbed: {
    paddingBottom: "3px",
    paddingTop: "4px",
    position: "relative"
  },
  center: {
    textAlign: "center"
  },
  close: {
    backgroundColor: "transparent",
    color: "#f00",
    boxShadow: "none"
  },
  dateTime: {
    "& .rdtPicker": {
      marginLeft: "160px"
    }
  },
  dateTimeInput: {
    color: "#f44336",
    fontSize: "1.3em",
    textAlign: "center",
  },
  dateTimeInputPlain: {
    color: "#777777",
    fontSize: "1.3em",
    textAlign: "center",
  },
  endOfDayAgendaItem: {
    display: "flex",

    "& span": {
      flex: "1 1 0"
    }
  },
  exportButton: {
    color: "#fff",
    fontSize: "1.2em",
    fontWeight: "600",
    "&:hover": {
      color: "#fff",
    }
  },
  exportCard: {
    "& h3": {
      display: "inline",
      flexGrow: "1",
      margin: "0"
    },
    alignItems: "center",
    display: "flex",
    marginBottom: "16px",
    maxWidth: "580px",
    padding: "16px",
    textAlign: "left",
    ...lightBoxShadow
  },
  exportInlineButton: {
    marginRight: "16px"
  },
  headerButtons: {
    marginTop: "30px",
    alignItems: "flex-start",
    display: "inline-flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    width: "100%"
  },
  headerButtonContainer: {
    alignItems: "center",
    display: "inline-flex !important",
    justifyContent: "space-between",
    marginTop: "-20px",
    paddingLeft: "0px",
    paddingRight: "0px",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "-20px",
      marginRight: "-20px",
      padding: "0"
    },
  },
  innerSearchContainer: {
    display: "inline-block",
    marginBottom: "12px",
    maxWidth: "350px",
    textAlign: "center"
  },
  labelGridItem: {
    display: "flex",
    justifyContent: "center"
  },
  largeText: {
    fontSize: "2em",
    fontWeight: "600",
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left"
  },
  legend: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  legendInner: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    "& span": {
      margin: "2px"
    }
  },
  marginRight: {
    marginRight: "16px"
  },
  marginTop: {
    marginTop: "16px"
  },
  monthlyCount: {
    float: "left",
    fontWeight: "600",
    marginRight: "10px"
  },
  monthlyExpiration: {
    fontWeight: "600",
    marginTop: "2px"
  },
  noMargin: {
    margin: "0"
  },
  noteHeader: {
    fontSize: "1.2em",
    fontWeight: "600",
    margin: "16px 0px 8px",
  },
  padding: {
    bottomPadding: "10px",
    topPadding: "20px",
  },
  productInfoSpan: {
    marginTop: "-44px",
    verticalAlign: "top",
  },
  publishedIndicatorOff: {
    backgroundColor: "#f44336",
  },
  publishedIndicatorOn: {
    backgroundColor: "#92dce5",
  },
  publishedIndicatorLeft: {
    border: "1px solid #ffffffaa",
    borderRadius: "4px",
    bottom: "6px",
    height: "8px",
    position: "absolute",
    width: "8px",
  },
  publishedIndicatorRight: {
    borderLeft: "1px solid white",
    bottom: "-5px",
    position: "absolute",
    right: "-10px",
    top: "-5px",
    width: "5px",
  },
  right: {
    textAlign: "right",
  },
  saveLabel: {
    color: successColor[2],
    margin: "8px 0px"
  },
  searchInputContainer: {
    maxWidth: "200px",
    textAlign: "center"
  },
  shippingType: {
    position: "absolute",
    marginRight: "10px",
    bottom: "2px"
  },
  summaryCard: {
    "& h2": {
      margin: "36px 16px 0px 16px",
      paddingTop: "16px"
    },
    "& p": {
      margin: "0px 16px 16px"
    },
    marginBottom: "16px",
    padding: "0",
    textAlign: "left",
    ...lightBoxShadow
  },
  summaryGridItem: {
    maxWidth: "700px"
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0"
  },
  tableActionButtonLeft: {
    width: "30px",
    height: "30px",
    padding: "0",
    position: "absolute",
    left: "13px",
    top: "10px"
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px"
  },
  tableActionButtonIconLeft: {
    width: "30px",
    height: "30px"
  },
  tagWrapper: {
    display: "flex",
    flexFlow: "row wrap",
    "& span": {
      textAlign: "left",
      flex: "1 0 30%",

      [theme.breakpoints.down("lg")]: {
        flex: "1 0 40%"
      },

      [theme.breakpoints.down("sm")]: {
        flex: "1 0 90%"
      },
    }
  },
  warning: {
    color: "#f44336"
  },
  widthFixed50: {
    textAlign: "right",
    width: "50px"
  },
  widthFixed75: {
    textAlign: "right",
    width: "75px"
  },
  widthFixed75Bold: {
    fontWeight: "600",
    paddingRight: "0 !important",
    textAlign: "right",
    width: "75px"
  },
  tooltip
});

export default reportStyle;
