import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import moment from "moment";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import Datetime from "react-datetime";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";

import api from "state/api";
import reportStyles from "assets/jss/material-dashboard-pro-react/views/reportStyles.jsx";
import { sortSizes } from "apparel-sorter";
import { withStyles } from "@material-ui/core/styles";
import withGracefulUnmount from "react-graceful-unmount";

class QuickShipReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cachePrefix: "",
      containsMixedStatuses: false,
      filterIndex: 0,
      filteredOrders: [],
      filteredSkus: [],
      groupTagData: [],
      newStatusSelected: null,
      orders: [],
      pickupBoxData: [],
      pickupTagData: [],
      purchasingData: [],
      searchStatus: "Ready ",
      searchString: "",
      selectedEndDate: moment().endOf("month"),
      selectedStartDate: moment().startOf("month"),
      tagData: [],
      skus: []
    };

    this.defaultSearch = "";
    this.isSearching = false;
    this.selectedItem = {};
    this.textEntered = "";
  }

  componentDidMount() {
    this.search({
      reportType: "quick-ship",
      startDate: moment()
        .startOf("month")
        .format("YYYY/MM/DD HH:mm:ss"),
      endDate: moment()
        .endOf("month")
        .format("YYYY/MM/DD HH:mm:ss")
    });
  }

  organizeResults(filtered) {
    // Consolidate by product name
    var artNums = {};
    var counts = {};
    var totals = {};
    var paidTotals = {};
    var data = {};
    var skus = {};
    var uniqueSkus = new Set();
    var filteredSkus = new Set();
    var filteredSimpleSkus = new Set();
    filtered.forEach(order => {
      // don't render null orders
      if (order.id == null) {
        return;
      }
      order.line_items.forEach(item => {
        if (!item.size) {
          item.size = "";
        }
        if (!item.color) {
          item.color = "";
        }
        if (!item.style) {
          item.style = "";
        }
        if (
          item.size.length > 0 ||
          item.color.length > 0 ||
          item.style.length > 0
        ) {
          var organization = item.collection_name
            ? item.collection_name
            : "[NONE]";
          var uniqueSKU = `${item.sku} - ${item.title} - ${organization}`;
          uniqueSkus.add(uniqueSKU);
          filteredSkus.add(uniqueSKU);
          filteredSimpleSkus.add(item.sku);
          var count = counts[item.title] || 0;
          var total = totals[item.title] || 0;
          var paidTotal = paidTotals[item.title] || 0;
          var sCount = counts[`${item.title}|${item.style}`] || 0;
          var cCount = counts[`${item.title}|${item.style}|${item.color}`] || 0;
          var products = data[item.title] || {};
          var styles = products[item.style] || {};
          var colors = styles[item.color] || {};
          var sizes = colors[item.size] || {};
          var prices = sizes[item.price] || 0;
          var quantity = parseInt(item.quantity);
          artNums[item.title] = order.art_number;
          counts[item.title] = count + quantity;
          counts[`${item.title}|${item.style}`] = sCount + quantity;
          counts[`${item.title}|${item.style}|${item.color}`] =
            cCount + quantity;
          sizes[item.price] = prices + quantity;
          colors[item.size] = sizes;
          styles[item.color] = colors;
          products[item.style] = styles;
          if (
            item.size.length > 0 ||
            item.color.length > 0 ||
            item.style.length > 0
          ) {
            data[item.title] = products;
            skus[`${item.title}|${item.style}|${item.color}|${item.size}`] =
              item.sku;

            totals[item.title] = total + parseFloat(item.price) * quantity;
          }
          if (
            order.payment_type !== "PENDING" &&
            order.payment_type !== "VOIDED"
          ) {
            paidTotals[item.title] =
              paidTotal + parseFloat(item.price) * quantity;
          }
        }
      });
    });
    this.setState({ skus: [...uniqueSkus].sort() });
    this.setState({ filteredSkus: [...filteredSkus].sort() });

    var rows = [];
    var productsSorted = Object.keys(data).sort();
    productsSorted.forEach(product => {
      rows.push({
        product: product,
        style: "",
        color: "",
        size: "",
        count: counts[product],
        artNumber: artNums[product]
      });

      var styles = data[product] || {};
      var stylesSorted = Object.keys(styles)
        .sort()
        .reverse();
      stylesSorted.forEach(style => {
        rows.push({
          product: "",
          style: style,
          color: "",
          size: "",
          count: counts[`${product}|${style}`]
        });

        var colors = styles[style] || {};
        var colorsSorted = Object.keys(colors).sort();
        colorsSorted.forEach(color => {
          rows.push({
            product: "",
            style: "",
            color: color,
            size: "",
            count: counts[`${product}|${style}|${color}`]
          });

          var sizes = colors[color] || {};
          var sizesSorted = sortSizes(Object.keys(sizes));
          sizesSorted.forEach(size => {
            var prices = sizes[size] || {};
            var pricesSorted = Object.keys(prices).sort();
            pricesSorted.forEach(price => {
              var sku = skus[`${product}|${style}|${color}|${size}`];
              var total = prices[price] || 0;
              rows.push({
                product: "",
                style: "",
                color: "",
                price: price,
                sku: sku,
                size: size,
                count: total
              });
            });
          });
        });
      });
      var total = totals[product] ? totals[product] : 0;
      var paidTotal = paidTotals[product] ? paidTotals[product] : 0;
      rows.push({
        product: "",
        style: "",
        color: "",
        price: "Total",
        size: "",
        count: total.toFixed(2)
      });
      rows.push({
        product: "",
        style: "",
        color: "",
        price: "Paid",
        size: "",
        count: paidTotal.toFixed(2)
      });
    });

    const fo = this.state.filteredOrders.filter(order =>
      order.line_items.filter(e => filteredSimpleSkus.has(e.sku))
    );
    this.setState({ filteredOrders: fo });
    this.setState({ purchasingData: rows });
  }

  search(body) {
    if (!body.startDate) {
      body.startDate = moment()
        .startOf("month")
        .format("YYYY/MM/DD HH:mm:ss");
    }
    if (!body.endDate) {
      body.endDate = moment()
        .endOf("month")
        .format("YYYY/MM/DD HH:mm:ss");
    }
    const params = Object.keys(body)
      .map(key => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(body[key]);
      })
      .join("&");
    fetch("/api/orders?" + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    })
      .then(response => api.authCheck(response))
      .then(data => {
        if (data["status"]) {
          this.isSearching = false;

          var orders = data["data"];
          this.setState({ filteredOrders: orders });
          this.setState({ orders: orders });
          this.setState({
            containsMixedStatuses: data["mixed"] && orders.length > 0
          });
          this.organizeResults(orders);
        }
      });
  }

  handleStartTimeChange(date) {
    this.setState({ selectedStartDate: date });
    this.search({
      reportType: "quick-ship",
      startDate: date.format("YYYY/MM/DD HH:mm:ss"),
      endDate: this.state.selectedEndDate.format("YYYY/MM/DD HH:mm:ss")
    });
  }

  handleEndTimeChange(date) {
    this.setState({ selectedEndDate: date });
    this.search({
      reportType: "quick-ship",
      startDate: this.state.selectedStartDate.format("YYYY/MM/DD HH:mm:ss"),
      endDate: date.format("YYYY/MM/DD HH:mm:ss")
    });
  }

  renderData() {
    const { classes } = this.props;
    var data = this.state.purchasingData;
    return data.map(order => {
      var color = "plain";
      if (order.product.length > 0) {
        color = "product";
      } else if (order.style.length > 0) {
        color = "style";
      } else if (order.color.length > 0) {
        color = "color";
      }
      return {
        color: color,
        data: [
          <div key="empty"></div>,
          <div key="id">
            {order.product.length > 0 ? (
              <span>
                <strong>{order.product}</strong>
                {order.artNumber > 0 ? (
                  <span className={classes.artNumber}>
                    Art #: {order.artNumber}
                  </span>
                ) : null}
              </span>
            ) : null}
          </div>,
          <div key="style">{order.style}</div>,
          <div key="color">{order.color}</div>,
          <div key="sku">{order.sku}</div>,
          <div key="price">{order.price}</div>,
          <div key="size">{order.size}</div>,
          <div key="count">
            <strong>{order.count}</strong>
          </div>
        ]
      };
    });
  }

  render() {
    const { classes } = this.props;
    const headers = [
      { label: "Product", key: "product" },
      { label: "Style", key: "style" },
      { label: "Color", key: "color" },
      { label: "SKU", key: "sku" },
      { label: "Price", key: "price" },
      { label: "Size", key: "size" },
      { label: "Qty", key: "count" }
    ];
    return (
      <GridContainer justify="center">
        <GridItem xs={5} className={classes.right}>
          <Datetime
            className={classes.dateTime}
            defaultValue={moment()
              .startOf("month")
              .format("YYYY-MM-DD HH:mm:ss Z")}
            value={this.state.selectedStartDate}
            inputProps={{
              placeholder: "Choose a start time",
              className: classes.dateTimeInputPlain,
              readOnly: true
            }}
            onChange={e => this.handleStartTimeChange(e)}
            onChangeRaw={e => this.handleStartTimeChangeRaw(e.target.value)}
          />
        </GridItem>
        <GridItem className={classes.center}>to</GridItem>
        <GridItem xs={5}>
          <Datetime
            className={classes.dateTime}
            defaultValue={moment()
              .endOf("month")
              .format("YYYY-MM-DD HH:mm:ss Z")}
            value={this.state.selectedEndDate}
            inputProps={{
              placeholder: "Choose an end time",
              className: classes.dateTimeInputPlain,
              readOnly: true
            }}
            onChange={e => this.handleEndTimeChange(e)}
            onChangeRaw={e => this.handleEndTimeChangeRaw(e.target.value)}
          />
        </GridItem>
        {this.state.purchasingData.length > 0 ? (
          <GridItem xs={10}>
            <Card className={classes.productCard}>
              <CardHeader color="primary" icon>
                <CardText color="rose" className={classes.dateCardText}>
                  <CSVLink
                    data={this.state.purchasingData}
                    headers={headers}
                    filename={"purchasing.csv"}
                    className={classes.exportButton}
                  >
                    Export CSV
                  </CSVLink>
                </CardText>
              </CardHeader>
              <CardBody className={classes.cardBodyVariants}>
                <Table
                  tableHead={[
                    "",
                    "Product",
                    "Style",
                    "Color",
                    "SKU",
                    "Price",
                    "Size",
                    "Qty"
                  ]}
                  tableData={this.renderData()}
                  customHeadCellClasses={[
                    classes.widthFixed75,
                    classes.widthFixed75,
                    classes.widthFixed75
                  ]}
                  customHeadClassesForCells={[5, 6, 7]}
                  customCellClasses={[
                    classes.widthFixed75,
                    classes.widthFixed75,
                    classes.widthFixed75
                  ]}
                  customClassesForCells={[5, 6, 7]}
                />
              </CardBody>
            </Card>
          </GridItem>
        ) : null}
      </GridContainer>
    );
  }
}

QuickShipReport.propTypes = {
  classes: PropTypes.object
};

export default withStyles(reportStyles)(withGracefulUnmount(QuickShipReport));
